import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import { submitOrder } from '../../api/order.api';
import { AppContext } from '../../context/AppContext';
import isEmail from '../../utils/validation.utils';
import OrderValidationMessage from './OrderValidationMessage';

const Order: React.FC = props => {
    const { selectedDuration, selectedParty, guestCount, distance, selectedOffer, selectedBundles, totals, selectedServices, selectedPlace, date, clientStocking } = React.useContext(AppContext);

    const [email, setEmail] = React.useState<string>("");
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value);

    const [name, setName] = React.useState<string>("");
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

    const [rodo, setRodo] = React.useState<boolean>(false);
    const handleRodoChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setRodo(prev => !prev);

    const [marketing, setMarketing] = React.useState<boolean>(false);
    const handleMarketingChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setMarketing(prev => !prev);

    const [message, setMessage] = React.useState<string>("");
    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value);

    const [validated, setValidated] = React.useState<boolean>(true);
    const [sent, setSent] = React.useState<boolean>(false);
    const [isSending, setIsSending] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<any>({});

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setIsSending(true);

        setSent(false);

        if (!distance
            || totals.totals < 1
            || guestCount < 1
            || !selectedOffer
            || !isEmail(email)
            || name === ""
            || !rodo
            || !marketing
            || selectedParty === "") {
            setValidated(false);
            return console.log("Validation failed ❌");
        }

        setValidated(true);

        const data = {
            name,
            email,
            message,
            party: selectedParty,
            distance: {
                distance: distance.distance.value / 1000,
                price: distance.price,
                destination: selectedPlace
            },
            duration: selectedDuration,
            offer: {
                name: selectedOffer,
                price: totals.offer,
                bundles: selectedBundles,
                services: selectedServices
            },
            guestCount,
            date,
            clientStocking,
            totals
        };

        submitOrder(data).then(res => {
            setResponse(res);
            setSent(true);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsSending(false);
        });
    }

    const isReadyToBeSent = selectedOffer && selectedParty && selectedPlace;

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction='column' sx={{ marginTop: 2, marginBottom: 2, maxWidth: 500 }}>
                    <Grid item container spacing={2}>
                        <Grid item>
                            <TextField
                                type={'email'}
                                name='email'
                                label='E-mail'
                                required
                                placeholder='zamowienie@koktajlove.pl'
                                onChange={handleEmailChange}
                                value={email} />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={'text'}
                                name='name'
                                required
                                label='Imię i nazwisko'
                                onChange={handleNameChange}
                                value={name} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Wiadomość"
                            multiline
                            fullWidth
                            rows={3}
                            onChange={handleMessageChange}
                            value={message} />
                    </Grid>
                </Grid>
                <FormGroup sx={{ marginBottom: 2, marginTop: 2 }}>
                    <FormControlLabel
                        control={<Checkbox size='small' value={rodo} onClick={handleRodoChange} name='rodo' />}
                        label={<Typography variant='caption'>Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do przygotowania i przesłania oferty obsługi barmańskiej  zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE</Typography>} />
                    <FormControlLabel control={<Checkbox size='small' value={marketing} onClick={handleMarketingChange} name='marketing' />}
                        label={<Typography variant='caption'>Wyrażam zgodę na otrzymywanie od KOKTAJLOVE Alicja Deruś drogą elektroniczną na wskazany przeze mnie w Formularzu adres e-mail informacji handlowych, dotyczących usług oferowanych przez  KOKTAJLOVE Alicja Deruś   w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)</Typography>} />
                </FormGroup>
                <Button disabled={!isReadyToBeSent || isSending} type='submit' color='primary' variant='contained' size={'large'} fullWidth disableElevation>
                    {isSending ? (<><CircularProgress size={20} color='inherit' /><Typography variant='inherit' sx={{ marginLeft: 1 }}>Wysyłanie wiadomości...</Typography></>) : (<Typography variant='inherit'>Złóż zapytanie</Typography>)}
                </Button>
                <OrderValidationMessage sent={sent} validated={validated} />
            </form>
        </div>
    );
}

export default Order;
