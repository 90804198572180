import { Container, Grid } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../context/AppContext'
import CardSimple from '../../Card/Card'
import CardContent from '../../Card/CardContent'
import DatePicker from '../../DatePicker/DatePicker'
import Distance from '../../Distance/Distance'
import Guests from '../../Guests/Guests'
import SectionTitle from '../../SectionTitle/SectionTitle'
import TimeAvailability from '../../TimeAvailability/TimeAvailability'

const Details: React.FC = props => {
    const { selectedParty } = React.useContext(AppContext);
    if (!selectedParty) return null;

    return (
        <Container maxWidth="lg">
            <SectionTitle title="Szczegóły wydarzenia" />
            <CardSimple light>
                <CardContent>
                    <Grid container spacing={6} direction='column'>
                        <Grid item>
                            <DatePicker />
                        </Grid>
                        <Grid item>
                            <Distance />
                        </Grid>
                        <Grid item>
                            <TimeAvailability />
                        </Grid>
                        <Grid item>
                            <Guests />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardSimple>
        </Container>
    )
}

export default Details