import clsx from 'clsx';
import React from 'react'

interface CardContentProps {
    shrink?: true;
}

const CardContent: React.FC<CardContentProps> = props => {
    const { children, shrink } = props;
    return (
        <div className={clsx('card-content', shrink && 'shrink')}>
            {children}
        </div>
    );
}

export default CardContent;
