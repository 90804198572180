import React from "react";

const getSlidesCount = () => {
    if (typeof window === "undefined") return 4;

    const { innerWidth } = window;
    if (innerWidth > 960) {
        return 4;
    }
    if (innerWidth > 720) {
        return 3.6;
    }
    if (innerWidth > 480) {
        return 2.4;
    }
    return 1.8;
}

const useSwiperSlides = () => {
    const [slides, setSlides] = React.useState<number>(getSlidesCount);

    React.useLayoutEffect(() => {
        if (typeof window === "undefined") return;
        function updateSlidesCount() {
            const { innerWidth } = window;
            if (innerWidth > 1280) {
                return setSlides(getSlidesCount);
            }
            if (innerWidth > 720) {
                return setSlides(getSlidesCount);
            }
            return setSlides(getSlidesCount);
        }

        window.addEventListener('resize', updateSlidesCount);
        return () => window.removeEventListener('resize', updateSlidesCount);
    });

    return slides;
}

export default useSwiperSlides;