import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react'
import { OfferInterface } from '../../api/offer.api';
import { AppContext } from '../../context/AppContext';
import CardSimple from '../Card/Card';
import CardContent from '../Card/CardContent';
import CardDecoration from '../Card/CardDecoration';
import Carousel from '../Carousel/Carousel';
import Price from '../Price/Price';
import './Offers.css';

interface OfferProps extends OfferInterface {
    index?: number;
}

const Offer: React.FC<OfferProps> = props => {
    const { name, price, index } = props;
    const { selectOffer, selectedOffer } = React.useContext(AppContext);
    const handleClick = React.useCallback(() => selectOffer(props), [props]);

    const isActive = selectedOffer && name === selectedOffer;

    const isCustom = price === 0;

    const decorationClass = isCustom ? 'card-decoration-highlighted' : `card-decoration-${index}`;

    return (
        <CardSimple className={clsx('offer', isActive && 'active', decorationClass)} onClick={handleClick}>
            <CardDecoration />
            <CardContent>
                {!isCustom && <Typography variant='caption'>Pakiet</Typography>}
                <Typography variant='h4' sx={{ marginBottom: 1 }}>{name}</Typography>
                {!isCustom && <Price light isStockingAllowed price={price} />}
            </CardContent>
        </CardSimple>
    );
}

const Offers: React.FC = props => {
    const { offers } = React.useContext(AppContext);

    return (
        <div>
            <Carousel>
                {offers
                    .map(offer => ({ ...offer, key: JSON.stringify(offer) }))
                    .map((offer, index) => <Offer index={index} {...offer} />)}
            </Carousel>
        </div>
    )
}

export default Offers
