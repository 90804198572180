import { useTheme } from "@mui/material";
import React from "react";

const isBrowser = (breakpoint: number) => {
    if (typeof window === "undefined") return false;

    const { innerWidth } = window;

    return innerWidth < breakpoint;
}

const useMobile = (breakpoint?: number): boolean => {
    const theme = useTheme();
    const [mobile, setMobile] = React.useState(isBrowser(breakpoint || theme.breakpoints.values.sm));

    React.useLayoutEffect(() => {
        if (typeof window === "undefined") return;

        function isMobile() {
            return setMobile(isBrowser(breakpoint || theme.breakpoints.values.sm));
        }

        window.addEventListener('resize', isMobile);
        return () => window.removeEventListener('resize', isMobile);
    });

    return mobile;
}

export default useMobile;