import { Grid, Typography } from '@mui/material';
import React from 'react'
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';
import Title from '../Title/Title';
import { Places } from './Places';

const Distance: React.FC = () => {
    const { distance, selectedPlace } = React.useContext(AppContext);

    return (
        <>
            <Title title="Lokalizacja imprezy" />
            <Places />
            {distance && (
                <Grid container columnSpacing={2} sx={{ marginTop: 1 }}>
                    <Grid item>
                        <Typography variant='caption'>Cel: <Typography variant='caption' color={'primary'}>{selectedPlace}</Typography></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>Dystans: <Typography variant='caption' color={'primary'}>{distance.distance.text}</Typography></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>Koszt <Typography variant='caption' color={'primary'}>{distance.price} {CURRENCY}</Typography></Typography>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default Distance
