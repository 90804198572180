import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';
import Title from '../Title/Title';

interface DurationProps {
    isActive: boolean;
    duration: number;
}

const Duration: React.FC<DurationProps> = props => {
    const { selectDuration } = React.useContext(AppContext);
    const { isActive, duration } = props;

    const handleClick = React.useCallback(() => selectDuration(duration), [duration]);

    return (
        <Button color={'primary'} variant={isActive ? 'contained' : 'outlined'} size='small' disableElevation onClick={handleClick}>{duration}</Button>
    );
}

const TimeAvailability: React.FC = () => {
    const { durationsAvailable, selectedDuration, totals } = React.useContext(AppContext);

    return (
        <div>
            <Title title="Czas trwania" />
            <Grid container spacing={1}>
                {durationsAvailable.map((el, index) => {
                    return <Grid item key={`${JSON.stringify(el)}${index}`}><Duration isActive={selectedDuration === el} duration={el} /></Grid>;
                })}
            </Grid>
            <Grid container sx={{ marginTop: 1 }}>
                <Grid item>
                    <Typography variant='caption'>Cena: <Typography variant='caption' color={'primary'}>{totals.duration} {CURRENCY}</Typography></Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default TimeAvailability;
