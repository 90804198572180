import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react'
import { AppContext } from '../../context/AppContext';
import CardSimple from '../Card/Card';
import CardContent from '../Card/CardContent';
import CardDecoration from '../Card/CardDecoration';

interface PartyProps {
    name: string;
    index: number;
}

const Party: React.FC<PartyProps> = props => {
    const { name, children } = props;
    const { selectParty, selectedParty } = React.useContext(AppContext);

    const handleSelectParty = () => {
        selectParty(name);
    };

    const isSelected = selectedParty === name;

    return (
        <CardSimple className={clsx('party', `card-decoration-${props.index}`)} onClick={handleSelectParty} isActive={isSelected}>
            <CardDecoration />
            <CardContent>
                <Typography variant='h4'>{name}</Typography>
            </CardContent>
        </CardSimple>
    )
}

export default Party;
