import { Grid, Typography } from '@mui/material';
import React from 'react'
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';
import Price from '../Price/Price';

interface SummaryProps {
    primaryColor?: true;
    vertical?: true;
}

const Summary: React.FC<SummaryProps> = props => {
    const { totals } = React.useContext(AppContext);
    const { primaryColor, vertical } = props;
    return (
        <Grid container columnSpacing={4} direction={'row'} justifyContent={'stretch'} alignItems={'center'}>
            <Grid item>
                <Grid container columnSpacing={1} direction={vertical ? 'column' : 'row'}>
                    <Grid item>
                        <Typography variant='body2' color={'text.secondary'}>Obsługa</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>{totals.service} {CURRENCY}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container columnSpacing={1} direction={vertical ? 'column' : 'row'}>
                    <Grid item>
                        <Typography variant='body2' color={'text.secondary'}>Dojazd</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>{totals.distance} {CURRENCY}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item flexGrow={vertical ? 1 : 0}>
                <Grid container columnSpacing={1} direction={vertical ? 'column' : 'row'}>
                    <Grid item>
                        <Typography variant='body2' color={'text.secondary'}>Koktajle</Typography>
                    </Grid>
                    <Grid item>
                        <Price isStockingAllowed price={totals.offer + totals.bundle} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container columnSpacing={1} alignItems={'center'}>
                    <Grid item>
                        <Typography variant='body2' color={'text.secondary'}>Suma</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={vertical ? 'body2' : 'inherit'} color={primaryColor && 'primary'} sx={{ fontWeight: 600 }}>{totals.totals} {CURRENCY}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Summary
