import clsx from "clsx";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { DrinkInterface } from "../../api/drinks.api";
import React from 'react';
import CardSimple from "../Card/Card";
import CardContent from "../Card/CardContent";
import { Typography } from "@mui/material";

interface DrinkProps extends DrinkInterface {
    isActive?: boolean;
}

export const Drink: React.FC<DrinkProps> = props => {
    const { name, photo, isActive } = props;

    return (
        <CardSimple className={clsx('drink', isActive && 'active')}>
            <div className='background'>
                {photo ? <GatsbyImage image={photo.gatsbyImageData} alt={photo.title} /> : <StaticImage src='../../images/empty.jpg' alt='empty glass' />}
            </div>
            <CardContent>
                <Typography variant='h4' color={'#FBEFE2'}>{name}</Typography>
            </CardContent>
        </CardSimple>
    );
}