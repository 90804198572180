import { Typography } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import './SectionTitle.css'
import decorationPrimary from '../../../static/decoration-primary.svg';
import decorationSecondary from '../../../static/decoration-secondary.svg';

export enum SectionTitleTypes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

export interface SectionTitleProps {
    title: string;
    subtitle?: string;
    type?: SectionTitleTypes;
    color?: SectionTitleTypes;
}

const SectionTitle: React.FC<SectionTitleProps> = props => {
    const { title, subtitle, type, color } = props;

    const sectionType = type || SectionTitleTypes.PRIMARY;
    const sectionColor = color || SectionTitleTypes.PRIMARY;

    return (
        <div className={clsx('section-title', sectionType, `color-${sectionColor}`)}>
            <div className='decoration'>
                <img src={sectionColor === SectionTitleTypes.PRIMARY ? decorationPrimary : decorationSecondary} />
            </div>
            <Typography variant={sectionType === SectionTitleTypes.PRIMARY ? 'h2' : 'h3'} color={'#FBEFE2'}>{title}</Typography>
            {subtitle && <Typography variant='subtitle1' color={'#FBEFE2'} className='subtitle'>{subtitle}</Typography>}
        </div>
    )
}

export default SectionTitle