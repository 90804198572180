import { useTheme } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { DrinkInterface, getDrinks } from '../../api/drinks.api';
import { AppContext } from '../../context/AppContext';
import useMobile from '../../hooks/useMobile.hook';
import Carousel from '../Carousel/Carousel';
import { Drink } from './Drink';
import './Drinks.css';

const setupDrinks = (drinks: DrinkInterface[], nodes: any) => {
    return drinks.map(drink => {
        const node = nodes.filter(node => node.contentfulid == drink.contentful_id)[0];

        if (node) {
            node.name = drink.name;
        }

        return {
            ...drink,
            desc: nodes.filter(node => node.contentfulid === drink.contentful_id)[0],
            ...node
        }
    });
}

const Drinks: React.FC = props => {
    const { nodes } = useStaticQuery(
        graphql`
        query Drinks {
            allContentfulDrink {
              nodes {
                name
                contentfulid
                photo {
                  gatsbyImageData(quality: 100, breakpoints: 4, formats: [AUTO, WEBP], width: 240)
                  title
                }
              }
            }
          }       
        `
    ).allContentfulDrink;

    const { availableDrinks } = React.useContext(AppContext);

    const [drinks, setDrinks] = React.useState<DrinkInterface[]>([]);

    React.useEffect(() => {
        if (drinks.length > 0) return;
        getDrinks().then(drinks => {
            setDrinks(setupDrinks(drinks, nodes))
        });
    }, [drinks, nodes]);

    if (useMobile()) return (
        <Carousel>
            {drinks.filter(drink => availableDrinks.some(el => el === drink.name)).map(el => {
                return (
                    <Drink isActive={true} {...el} name={el.name} contentfulid={el.contentful_id.toString()} key={JSON.stringify(el)} />
                );
            })}
            {drinks.filter(drink => !availableDrinks.some(el => el === drink.name)).map(el => {
                return (
                    <Drink {...el} name={el.name} contentfulid={el.contentful_id.toString()} key={JSON.stringify(el)} />
                );
            })}
        </Carousel>
    );

    return (
        <div className="drinks">
            {drinks.filter(drink => availableDrinks.some(el => el === drink.name)).map(el => {
                return (
                    <Drink isActive={true} {...el} name={el.name} contentfulid={el.contentful_id.toString()} key={JSON.stringify(el)} />
                );
            })}
            {drinks.filter(drink => !availableDrinks.some(el => el === drink.name)).map(el => {
                return (
                    <Drink {...el} name={el.name} contentfulid={el.contentful_id.toString()} key={JSON.stringify(el)} />
                );
            })}
        </div>
    );
}

export default Drinks;


