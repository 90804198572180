import { Typography } from '@mui/material';
import React from 'react'
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';

interface PriceProps {
    isStockingAllowed?: true;
    price: number;
    light?: true;
}

const Price: React.FC<PriceProps> = props => {
    const { isStockingAllowed, price, light } = props;
    const { clientStocking } = React.useContext(AppContext);

    if (isStockingAllowed && clientStocking) return (
        <Typography variant='body2'>
            <Typography variant="body2" color={!light ? "#57c1a1" : "#7dcfb6"} display={'inline-block'}>0 {CURRENCY}</Typography>
            <Typography variant="body2" display={'inline-block'} sx={{ marginLeft: 1, textDecoration: 'line-through', opacity: 0.5 }}>{price} {CURRENCY}</Typography>
        </Typography>
    )

    return (
        <Typography variant='body2'>{price} {CURRENCY}</Typography>
    )
}

export default Price