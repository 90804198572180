import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react'
import { OfferInterface } from '../../api/offer.api';
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';
import CardSimple from '../Card/Card';
import CardContent from '../Card/CardContent';
import CardDecoration from '../Card/CardDecoration';
import Price from '../Price/Price';
import './Bundles.css';

export interface BundleInterface extends OfferInterface {
    index: number;
}

const Bundle: React.FC<BundleInterface> = props => {
    const { name, price } = props;

    const { selectedBundles, toggleSelectedBundles } = React.useContext(AppContext);

    const isSelected = selectedBundles.some(el => el === name);

    const handleClick = React.useCallback(() => toggleSelectedBundles(name), [name, selectedBundles]);

    return (
        <CardSimple className={clsx('bundle', `card-decoration-${props.index}`)} onClick={handleClick} isActive={isSelected}>
            <CardDecoration />
            <CardContent>
                <Typography variant='caption' color={isSelected ? 'primary.main' : 'text.secondary'}>Zestaw</Typography>
                <Typography variant='h4' sx={{ marginBottom: 1 }}>{name}</Typography>
                <Price isStockingAllowed price={price} />
            </CardContent>
        </CardSimple>
    );
}

const Bundles: React.FC = props => {
    const { bundles } = React.useContext(AppContext);

    return (
        <Grid container spacing={2}>
            {bundles.map(bundle => <Grid key={JSON.stringify(bundle)} item xs={6} sm={4} lg={3}><Bundle {...bundle} /></Grid>)}
        </Grid>
    )
}

export default Bundles
