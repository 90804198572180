import axios from "axios";
import { Totals } from "../context/AppContext";
import { API_BASE_URL } from "./settings.api";

interface OrderDistance {
    distance: number;
    price: number;
    destination: string;
}

interface OrderOffer {
    name: string;
    price: number;
    bundles: string[];
    services: string[];
}

export interface OrderInterface {
    name: string;
    email: string;
    message: string;
    date: Date;
    party: string;
    guestCount: number;
    duration: number;
    distance: OrderDistance;
    offer: OrderOffer;
    totals: Totals;
    clientStocking: boolean;
}

export interface OrderInterfaceResponse {
    order: OrderInterface;
    timestamp: number;
}

export const submitOrder = async (data: OrderInterface): Promise<OrderInterfaceResponse> => {
    try {
        const response = await axios(`${API_BASE_URL}/orders`, {
            method: 'POST',
            data
        });
        return response.data as OrderInterfaceResponse;
    } catch (err) {
        console.log(err);
    }
}
