import { Alert, Box, Checkbox, Container, FormControlLabel, Grid, Snackbar, Typography } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../context/AppContext'
import Bundles from '../../Bundles/Bundles'
import CardSimple from '../../Card/Card'
import CardContent from '../../Card/CardContent'
import Drinks from '../../Drinks/Drinks'
import Offers from '../../Offers/Offers'
import SectionTitle, { SectionTitleTypes } from '../../SectionTitle/SectionTitle'
import Title from '../../Title/Title';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';;

const Menu: React.FC = props => {
    const { bundles, selectedParty, toggleClientStocking, clientStocking } = React.useContext(AppContext);


    const [openAlert, setOpenAlert] = React.useState<boolean>(false);
    const handleClose = () => setOpenAlert(false);

    React.useEffect(() => {

        setOpenAlert(clientStocking);

    }, [clientStocking])

    if (!selectedParty) return null;

    return (
        <>
            <Container maxWidth="lg">
                <SectionTitle title="Zaopatrzenie" subtitle="Zdecyduj kto będzie odpowiedzialny za zakup niezbędnych produktów" />
                <CardSimple light>
                    <CardContent>
                        <Box sx={{ maxWidth: 700 }}>
                            <Title
                                title='Zatowarowanie baru'
                                subtitle='Robienie zakupów to nasza specjalność. Jeśli chcesz, kupimy wszystko co niezbędne do zorganizowania imprezy. Możesz także zrobić to we własnym zakresie, a my tylko przygotujemy listę potrzebnych nam rzeczy.' />
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <InfoOutlinedIcon color='error' />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' color={'system.error'}>Organizator zapewnia wódkę</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={<Checkbox size='small' value={clientStocking} onChange={toggleClientStocking} />} label={`Zakupy składników zrobię we własnym zakresie`} />
                                <Typography variant='caption' color={'text.secondary'}>Listę towarów prześlemy mailem</Typography>
                            </Grid>
                        </Grid>

                    </CardContent>
                </CardSimple>
                <SectionTitle title="Karty drinków" type={SectionTitleTypes.SECONDARY} color={SectionTitleTypes.SECONDARY} />
                <Offers />
                {bundles.length > 0 && (
                    <>
                        <SectionTitle title="Wybór zestawów" type={SectionTitleTypes.SECONDARY} color={SectionTitleTypes.PRIMARY} />
                        <Bundles />
                    </>
                )}
                <SectionTitle title="Koktajle w pakiecie" type={SectionTitleTypes.SECONDARY} color={SectionTitleTypes.SECONDARY} />
                <Drinks />
            </Container>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Obniżono ceny kart alkoholi!
                </Alert>
            </Snackbar>
        </>
    )
}

export default Menu