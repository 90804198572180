import { Checkbox } from '@mui/material';
import React from 'react';
import { ServiceInterface } from '../../api/services.api';
import { AppContext } from '../../context/AppContext';
import { CURRENCY } from '../../settings/currency.settings';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Title from '../Title/Title';

export const Service: React.FC<ServiceInterface> = props => {
    const { name, value } = props;

    const { selectedServices, toggleSelectedServices } = React.useContext(AppContext);

    const defaultChecked = React.useMemo(() => selectedServices.includes(name), []);

    const handleClick = React.useCallback(() => toggleSelectedServices(name), [name, toggleSelectedServices]);

    return (
        <FormControlLabel control={<Checkbox size='small' onClick={handleClick} defaultChecked={defaultChecked} />} label={`${name}: ${value} ${CURRENCY}`} />
    )
}

const Services: React.FC = props => {
    const { services } = React.useContext(AppContext);

    return (
        <>
            <Title title="Opcje dodatkowe" />
            <FormGroup>
                {services.map(el => <Service key={JSON.stringify(el)} {...el} />)}
            </FormGroup>
        </>
    )
}

export default Services
