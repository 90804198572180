import { Container } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../context/AppContext'
import CardSimple from '../../Card/Card'
import CardContent from '../../Card/CardContent'
import SectionTitle, { SectionTitleTypes } from '../../SectionTitle/SectionTitle'
import Services from '../../Services/Services'

const AdditionalServices: React.FC = props => {
    const { selectedOffer } = React.useContext(AppContext);

    if (!selectedOffer) return null;

    return (
        <Container maxWidth="lg">
            <SectionTitle title="Opcje dodatkowe" type={SectionTitleTypes.SECONDARY} color={SectionTitleTypes.SECONDARY} />
            <CardSimple light>
                <CardContent>
                    <Services />
                </CardContent>
            </CardSimple>
        </Container>
    )
}

export default AdditionalServices