import { Typography } from '@mui/material';
import React from 'react'
import './Title.css';

export interface TitleProps {
    title?: string;
    subtitle?: string;
}

const Title: React.FC<TitleProps> = props => {
    const { title, subtitle } = props;

    return (
        <div className='element-title'>
            {title && <Typography variant='subtitle1' component={(props) => <p className='title'>{props.children}</p>}>{title}</Typography>}
            {subtitle && <Typography variant='body2' sx={{ color: 'text.secondary' }}>{subtitle}</Typography>}
        </div >
    )
}

export default Title