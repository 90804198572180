import Card from '@mui/material/Card'
import clsx from 'clsx';
import React from 'react';
import './Card.css';
import CardDecoration from './CardDecoration';

export interface CardProps {
    onClick?: () => void;
    isActive?: boolean;
    className?: string;
    light?: boolean;
    disableDecoration?: true;
}

const CardSimple: React.FC<CardProps> = (props) => {
    const { children, onClick, isActive, className, light, disableDecoration } = props;

    const active = React.useMemo(() => isActive, [isActive]);

    return (
        <Card onClick={onClick} className={clsx(active && 'active', className, 'card', onClick && 'clickable', light && 'light')}>
            {(light && !disableDecoration) && <CardDecoration />}
            {children}
        </Card>
    )
}

export default CardSimple