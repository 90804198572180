import { Container } from '@mui/material'
import React from 'react'
import { AppContext } from '../../../context/AppContext'
import CardSimple from '../../Card/Card'
import CardContent from '../../Card/CardContent'
import Order from '../../Order/Order'
import SectionTitle, { SectionTitleTypes } from '../../SectionTitle/SectionTitle'
import Summary from '../../Summary/Summary'
import SummaryWidget from '../../Summary/SummaryWidget'

const SummarySection: React.FC = props => {
    const { selectedParty } = React.useContext(AppContext);

    if (!selectedParty) return null;

    return (
        <>
            <Container maxWidth="md">
                <SectionTitle title="Podsumowanie" subtitle="Skontaktujemy się w sprawie potwierdzenia rezerwacji. Prześlij ofertę w PDF." />
                <CardSimple light>
                    <CardContent>
                        <Summary />
                        <Order />
                    </CardContent>
                </CardSimple>
            </Container>
            <SummaryWidget />
        </>
    )
}

export default SummarySection