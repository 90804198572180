import { Button, Grid, TextField } from '@mui/material';
import React from 'react'
import { getPartyInputs } from '../../api/parties.api';
import { AppContext } from '../../context/AppContext';
import { useDebounceNumber } from '../../hooks/useDebounce.hook';
import Title from '../Title/Title';

const Guests: React.FC = props => {
    const { guestCount, updateGuestCount, selectedParty } = React.useContext(AppContext);

    const [minInput, setMinInput] = React.useState<number>(0);
    const [maxInput, setMaxInput] = React.useState<number>(0);

    const [guests, setGuests] = React.useState<number>(guestCount);
    const addGuests = () => {
        setGuests(prev => {
            return prev < maxInput ? prev + 1 : maxInput
        });
    };
    const subtractGuests = () => {
        setGuests(prev => {
            return prev > minInput ? prev - 1 : minInput
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value, 10);

        if (value > maxInput) {
            return setGuests(maxInput);
        }

        if (value < minInput) {
            return setGuests(minInput);
        }

        setGuests(value);
    }

    const debouncedGuests = useDebounceNumber(guests, 800);

    React.useEffect(() => {

        if (!selectedParty) return;
        getPartyInputs({ party: selectedParty }).then(res => {
            if (!res) return;
            setMinInput(res.minGuestCount);
            setMaxInput(res.maxGuestCount);
            if (res.minGuestCount > guests) setGuests(res.minGuestCount);
            if (res.maxGuestCount < guests) setGuests(res.maxGuestCount);
        })

    }, [selectedParty]);

    React.useEffect(() => {
        updateGuestCount(debouncedGuests);
    }, [debouncedGuests]);


    return (
        <div>
            <Title title='Liczba gości' />
            <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                    <Button
                        variant='contained'
                        onClick={subtractGuests}
                        disabled={guests <= minInput}
                        color='primary'
                        size='small'
                        sx={{ minWidth: 40 }}
                        disableElevation>-</Button>
                </Grid>
                <Grid item>
                    <TextField
                        type={'number'}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ inputMode: 'numeric', max: maxInput, min: minInput }}
                        value={guests}
                        size={'small'}
                        onChange={handleChange}
                        sx={{ maxWidth: 75, textAlign: 'center' }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        onClick={addGuests}
                        disabled={guests >= maxInput}
                        color='primary'
                        size='small'
                        sx={{ minWidth: 40 }}
                        disableElevation>+</Button>
                </Grid>
            </Grid>

        </div>
    )
}

export default Guests;
