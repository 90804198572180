import React from 'react'
import { AppContext } from '../../context/AppContext';
import Party from './Party';
import { Container } from '@mui/material';
import SectionTitle from '../SectionTitle/SectionTitle';
import Carousel from '../Carousel/Carousel';
import './Parties.css';
import Loader from '../Loader/Loader';


const Parties: React.FC = () => {
    const { parties } = React.useContext(AppContext);

    return (
        <Container maxWidth="md">
            <SectionTitle
                title='Wybierz imprezę' />
            {parties.length < 1 ? (
                <Loader title='Ładowanie...' />
            ) : (
                <div className='parties'>
                    <Carousel>
                        {parties.map((party, index) => <Party key={JSON.stringify(party)} index={index} {...party} />)}
                    </Carousel>
                </div>
            )}
        </Container>
    )
}

export default Parties
