import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import React from 'react';
import { AppContext } from '../../context/AppContext';
import formatDate from '../../utils/date.utils';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Title from '../Title/Title';

const DatePicker: React.FC = props => {
    const { date, selectDate } = React.useContext(AppContext);

    const handleChange = (newValue: Date | null) => {
        selectDate(newValue);
    }

    const formattedDate = formatDate(date);

    return (
        <>
            <Title title="Data imprezy" />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                    label="Data wydarzenia"
                    inputFormat="dd/MM/yyyy"
                    value={formattedDate}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
        </>
    );
}

export default DatePicker;
