import { Snackbar, Alert, Modal, Card, Container, Box, Typography } from '@mui/material';
import React from 'react'
import CardContent from '../Card/CardContent';

interface OrderValidationMessageProps {
    sent: boolean;
    validated: boolean;
}

const OrderValidationMessage: React.FC<OrderValidationMessageProps> = props => {
    const { sent, validated } = props;

    const [show, setShow] = React.useState<boolean>(false);
    const handleClose = () => setShow(false);

    React.useEffect(() => {
        setShow(!validated || sent);
    }, [validated, sent]);

    return (
        <>
            <Snackbar open={show && !sent && !validated} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Niektóre pola nie zostały wypełnione
                </Alert>
            </Snackbar>
            <Modal open={show} onBackdropClick={handleClose}>
                <Box sx={{ position: 'absolute', left: '50%', top: '30%', transform: 'translate(-50%, -50%)' }}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ textAlign: 'center', marginBottom: 1, fontSize: 40 }}>🍹</Typography>
                            <Typography variant="h3" sx={{ marginBottom: 1, textAlign: 'center' }}>Dziękujemy za złożenie zapytania!</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>Skontaktujemy się z Tobą jak najszybciej</Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    )
}

export default OrderValidationMessage