import React from "react";
import { getPlaces } from "../../api/distance.api";
import { AppContext } from "../../context/AppContext";

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import useDebounce from "../../hooks/useDebounce.hook";

export interface PlaceProps {
    name: string;
}

export const Place: React.FC<PlaceProps> = props => {
    const { name } = props;
    const { selectPlace, selectedPlace } = React.useContext(AppContext);
    const handleClick = React.useCallback(() => selectPlace(name), [name]);

    const isSelected = name === selectedPlace;

    return (
        <div onClick={handleClick} style={{ color: isSelected && 'red' }}>{name}</div>
    )
}

interface PlacesProps {
}

interface ValueAutocomplete {
    label: string;
}

export const Places: React.FC<PlacesProps> = props => {
    // const { query } = props;

    const [query, setQuery] = React.useState<string>("");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value);
    }
    const debouncedQuery = useDebounce(query, 500);

    const { selectedPlace, selectPlace } = React.useContext(AppContext);

    const [places, setPlaces] = React.useState<string[]>([]);
    React.useEffect(() => {
        if (debouncedQuery.length < 2) {
            setPlaces([]);
            return;
        };

        getPlaces(debouncedQuery).then(response => setPlaces(response));

    }, [debouncedQuery]);

    const defValue = React.useMemo(() => selectedPlace, []);

    return (
        <div>
            <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) => option.label === value.label}
                defaultValue={defValue}
                noOptionsText={'Szukaj miejsca'}
                onChange={(e, v) => {
                    const value = v as ValueAutocomplete;
                    value && selectPlace(value.label);
                }}
                options={places.map(place => ({ label: place }))}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Lokalizacja" onChange={handleChange} value={{ label: query }} />}
            />
        </div>
    );
}