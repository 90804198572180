import React from 'react';

interface CardDecoration {
    className?: string;
}

const CardDecoration: React.FC<CardDecoration> = props => {
    const { className } = props;

    return (
        <div className='card-decoration'>
            <svg xmlns="http://www.w3.org/2000/svg" width="880" height="880" viewBox="0 0 880 880" className={className}>
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="440" cy="440" r="440" />
            </svg>
        </div>
    )
}

export default CardDecoration