import React from 'react'
import Template from '../components/layout';
import Parties from '../components/Parties/Parties';
import Details from '../components/Sections/Details/Details';
import Menu from '../components/Sections/Menu/Menu';
import AdditionalServices from '../components/Sections/AdditionalServices/AdditionalServices';
import SummarySection from '../components/Sections/Summary/Summary';
import Hero from '../components/Hero/Hero';
import { Helmet } from "react-helmet";

function IndexPage() {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Koktajlove 🍹 Bar mobilny na Twojej imprezie</title>
        <link rel="canonical" href="https://koktajlove.pl/" />
      </Helmet>
      <Template>
        <Hero title="Koktajlove" subtitle={true} />
        <Parties />
        <Details />
        <Menu />
        <AdditionalServices />
        <SummarySection />
      </Template>
    </>
  )
}

export default IndexPage;
