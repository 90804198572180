import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import useSwiperSlides from '../../hooks/useSwiperSlides.hook';
import { useTheme } from '@mui/material';
import 'swiper/css';

interface CarouselProps {

}

const Carousel: React.FC<CarouselProps> = props => {
    const { children } = props;

    const slides = useSwiperSlides();
    const theme = useTheme();
    const spacing = parseInt(theme.spacing(2).replace('px', ''), 10);

    return (
        <Swiper
            spaceBetween={spacing}
            slidesPerView={slides}>
            {React.Children.map(children, (child) => <SwiperSlide>{child}</SwiperSlide>)}
        </Swiper>
    )
}

export default Carousel