import { Box, Container } from '@mui/material';
import React from 'react'
import Card from '../Card/Card';
import CardContent from '../Card/CardContent';

import Summary from './Summary';
import './Summary.css';

const SummaryWidget: React.FC = props => {

    // const lg = useMediaQuery('(min-width:600px)');

    return (
        <Box className={'summary-widget'} sx={{ transform: 'translate(-50%, 0)' }}>
            <Container maxWidth="sm">
                <Card light disableDecoration>
                    <CardContent shrink>
                        <Summary primaryColor vertical />
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}

export default SummaryWidget
