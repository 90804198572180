import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import './Loader.css'

interface LoaderProps {
    title?: string;
}

const Loader: React.FC<LoaderProps> = props => {
    const { title } = props;
    return (
        <div className='loader'>
            <CircularProgress />
            <Typography variant='caption' color='#FBEFE2' sx={{ marginTop: 1 }}>{title}</Typography>
        </div>
    )
}

export default Loader